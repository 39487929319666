type PageData = {
  fiscal_year_start: number
  js_week_start_day: number
}

export let pageData: PageData = {
  fiscal_year_start: 1,
  js_week_start_day: 1,
}

if (typeof document !== 'undefined') {
  const script = document.getElementById('page_data-data-island')
  if (script) pageData = JSON.parse(script.innerHTML)
}

export function setPageData(data: PageData) {
  pageData = data
}
