import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import dayOfYear from 'dayjs/plugin/dayOfYear'
import duration, { Duration } from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import minMax from 'dayjs/plugin/minMax'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import updateLocale from 'dayjs/plugin/updateLocale'
import { pageData } from '../pageData'

dayjs.extend(advancedFormat)
dayjs.extend(dayOfYear)
dayjs.extend(duration)
dayjs.extend(isBetween)
dayjs.extend(minMax)
dayjs.extend(quarterOfYear)
dayjs.extend(customParseFormat)
dayjs.extend(updateLocale)

dayjs.updateLocale('en', {
  weekStart: pageData.js_week_start_day,
})

type HarvestUnitType =
  | dayjs.OpUnitType
  | dayjs.QUnitType
  | 'semimonth'
  | 'fiscal_year'

declare module 'dayjs' {
  interface Dayjs {
    add(value: number | Duration, unit?: HarvestUnitType): dayjs.Dayjs
    subtract(value: number | Duration, unit?: HarvestUnitType): dayjs.Dayjs
    startOf(unit: HarvestUnitType, start: boolean): dayjs.Dayjs
    endOf(unit: HarvestUnitType): dayjs.Dayjs
  }
}

// semimonth
dayjs.extend(function (_option, { prototype }) {
  const { add, startOf } = prototype

  prototype.add = function (number: number | Duration, unit?: HarvestUnitType) {
    if (unit !== 'semimonth') return add.call(this, number, unit)

    number = Number(number)

    // Add whole months first.
    let result = this.add(Math.trunc(number / 2), 'month')

    if (number % 2 === 0) return result

    const date = result.date()

    if (date > 15) {
      if (number > 0) result = result.add(1, 'month')
      return result.date(Math.min(date - 15, 15))
    }

    if (number < 0) result = result.subtract(1, 'month')
    return result.date(Math.min(date + 15, result.endOf('month').date()))
  }

  prototype.startOf = function (unit: HarvestUnitType, start: boolean = true) {
    if (unit !== 'semimonth') return startOf.call(this, unit, start)

    if (this.date() > 15) {
      if (start) return this.date(16).startOf('day')
      return this.endOf('month')
    }

    if (start) return this.startOf('month')
    return this.date(15).endOf('day')
  }
})

// fiscal_year
dayjs.extend(function (_option, { prototype }) {
  const { add, startOf } = prototype

  prototype.add = function (number: number | Duration, unit?: HarvestUnitType) {
    if (unit !== 'fiscal_year') return add.call(this, number, unit)

    return this.add(number, 'year')
  }

  prototype.startOf = function (unit: HarvestUnitType, start: boolean = true) {
    if (unit !== 'fiscal_year') return startOf.call(this, unit, start)

    const offset = pageData.fiscal_year_start - 1
    const result = this.add(-offset, 'month')
      .startOf('year')
      .add(offset, 'month')

    if (start) return result

    return start ? result : result.add(11, 'month').endOf('month')
  }
})

export default dayjs
