import { IconArrowLeft, IconArrowRight } from '@harvesthq/porchlight'
import classNames from 'classnames'
import dayjs from 'harvest/dayjs'
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'preact/compat'

export const DatePickerCalendar = forwardRef(function DatePickerCalendar(
  props,
  ref
) {
  const { message = null, onSelected, disabled, value: valueProp } = props

  const value = dayjs(valueProp).isValid()
    ? dayjs(valueProp).format('YYYY-MM-DD')
    : dayjs().format('YYYY-MM-DD')

  const [month, setMonth] = useState(value)

  useEffect(() => {
    setMonth(value)
  }, [value])

  useImperativeHandle(ref, () => ({
    setMonth(month) {
      if (dayjs(month).isValid()) {
        setMonth(dayjs(month).format('YYYY-MM-DD'))
      }
    },
  }))

  const headers = useMemo(() => {
    const names = []
    let day = dayjs().startOf('week')

    while (names.length < 7) {
      const text = day.format('dd')
      names.push(<div key={text}>{text}</div>)
      day = day.add(1, 'day')
    }

    return names
  }, [])

  const days = useMemo(() => {
    const days = []
    let day = dayjs(month).startOf('month').startOf('week')

    while (days.length < 42) {
      const iso = day.format('YYYY-MM-DD')
      const className = classNames('hui-datepicker-day', {
        'hui-datepicker-current': day.isSame(month, 'month'),
        'hui-datepicker-selected': day.isSame(value, 'day'),
        'hui-datepicker-today': day.isSame(dayjs(), 'day'),
        'hui-datepicker-disabled': disabled?.(iso),
      })

      const onClick = () => {
        if (!disabled?.(iso)) onSelected?.(iso)
      }

      days.push(
        <button
          type="button"
          key={iso}
          aria-label={day.format('MMMM D')}
          className={className}
          onClick={onClick}>
          {day.date()}
        </button>
      )

      day = day.add(1, 'day')
    }

    return days
  }, [disabled, month, onSelected, value])

  function changeMonth(event, by) {
    event.stopPropagation()
    setMonth(dayjs(month).add(by, 'month').format('YYYY-MM-DD'))
  }

  return (
    <div
      className="hui-datepicker"
      onMouseDown={(event) => {
        event.preventDefault()
      }}>
      <div className="hui-datepicker-header">
        <button
          type="button"
          aria-label="Previous month"
          onClick={(event) => changeMonth(event, -1)}
          className="hui-datepicker-month-nav">
          <IconArrowLeft />
        </button>
        <span>{dayjs(month).format('MMMM YYYY')}</span>
        <button
          type="button"
          aria-label="Next month"
          onClick={(event) => changeMonth(event, 1)}
          className="hui-datepicker-month-nav">
          <IconArrowRight />
        </button>
      </div>
      <div className="hui-datepicker-days-of-week">{headers}</div>
      <div className="hui-datepicker-days">{days}</div>
      {message ? <div className="hui-datepicker-footer">{message}</div> : null}
    </div>
  )
})
